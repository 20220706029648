import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        component: () => import('@/views/HomeView.vue'),
    },
    {
        path: '/coins/',
        component: () => import('@/views/CoinsView.vue'),
    },
    {
        path: '/coins/:coinId',
        component: () => import('@/views/CoinDetailView.vue'),
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router