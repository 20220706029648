<template>
<div>
  <nav class="navbar navbar-expand-lg navbar-light bg-light" aria-label="Main navigation">
    <div class="row w-100">
      <div class="col-md-12 col-lg-6 text-start">
        <router-link class="navbar-brand" to="/"><img src="@/assets/logo_transparent_cropped.png" alt="logo" id="logo"></router-link>
      </div>
      <div class="col-md-12 col-lg-6">
        <div class="input-group ps-4 pt-2">
          <input type="text" name="search" id="search" class="form-control" @keyup="submitSearch" v-model="searchString" placeholder="Search for your favorite crypto....">
          <div class="input-group-text bg-white">
            <i class="bi bi-search"></i>
          </div>
        </div>

        <div style="z-index: 100" class="bg-white p-3" v-if="searchResults">
          <table class="table table-hover">
            <tr v-for="coin in searchResults" :key="coin.id" @click="searchResults = null; searchString = ''; $router.push(`/coins/${coin.id}`)" style="cursor: pointer; height: 50px">
              <td class="fw-bold"><div class="text-start"><img :src="coin.large" :alt="coin.name + '-logo'" width="25" class="me-1">{{ coin.name }}<span class="text-muted fw-light">&nbsp;&#183;&nbsp;{{ coin.symbol.toUpperCase() }}</span></div></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </nav>

  <router-view></router-view>

  <footer class="px-5 py-4 mt-5 bg-light">
    <div class="row">
      <div class="col">
        <p class="fw-bold">Platform</p>
        <ul style="list-style-type: none; margin: 0; padding: 0;">
          <li><router-link to="/coins">Crypto Prices</router-link></li>
        </ul>
      </div>
      <div class="col">
        <p class="fw-bold">Company</p>
        <ul style="list-style-type: none; margin: 0; padding: 0;">
          <li><router-link to="/">Disclaimer</router-link></li>
          <li><router-link to="/">Privacy policy</router-link></li>
        </ul>
      </div>
      <div class="col">
        <p class="fw-bold">Contact us</p>
        <ul style="list-style-type: none; margin: 0; padding: 0;">
          <li><a href="https://t.me/acria_network">Telegram</a></li>
        </ul>
      </div>
    </div>
  </footer>
</div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',

  data() {
    return {
      searchString: "",
      searchResults: null
    }
  },

  methods: {
    submitSearch() {
      axios.get(`https://api.coingecko.com/api/v3/search?query=${this.searchString}`).then((response) => {
        this.searchResults = response.data.coins.slice(0, 5)
      });
    }
  }
}
</script>

<style>
:root {
  --light-green: rgba(52, 199, 89, 0.1);
  --green: #34b349;

  --light-red: rgba(255, 53, 53, 0.1);
  --red: #f02934;

  --acria-orange: #FF6C3F;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}

#logo {
  height: 50px;
  margin-left: 15px;
}

.chip-good {
  background-color: var(--light-green);
  color: var(--green);
}

.chip-bad {
  background-color: var(--light-red);
  color: var(--red);
}

.horizontal-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
  color: var(--acria-orange);
}
</style>
